<template>
  <div v-loading="loader">
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1>Edit Resource</h1>
      <bari-resource-form
        @updateBariResource="updateBariResource"
        :submit-button-text="'Update'"
        :bari-resource-data-to-edit="response.bariResource"
      />
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import BariResourceForm from "@/views/Pages/SuperAdmin/BariResource/BariResourceForm";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "EditBariResource",
  components: {BariResourceForm, BackButton},
  data() {
    return {
      loader: false,
      requestId: this.$route.params.id,
      response: {
        bariResource: {...this.$store.getters['BariResourceModule/getBariResource']}
      }
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  mounted() {
    this.getRequestedBariResource()
  },
  methods: {
    /**
     * Get requested Bari Resource
     */
    getRequestedBariResource() {
      let vm = this
      vm.loader = true
      const payload = {
        id: vm.requestId
      }
      vm.$store.dispatch('BariResourceModule/_getRequestedBariResource', payload)
        .then(response => {
          vm.response.bariResource = response.data.data
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.$router.push({name: 'BariResources'})
            return
          }

          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Bari Resource',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    },

    /**
     * Update Bari Resource
     * @param event
     */
    updateBariResource(event) {
      let vm = this
      vm.loader = true
      var formData = new FormData()
      _.each(event, (value, key) => {
        formData.append(key, value)
      })
      const config = {headers: {'content-type': 'multipart/form-data'}}
      vm.$store.dispatch('BariResourceModule/_updateBariResource', {config, formData})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Bari Resource Updated Successfully!'
          })
          vm.$router.back()
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Bari Resource',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="forms mt-4" v-loading="loaders.form">
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
        <card type="default" header-classes="bg-transparent">

          <div class="row">
            <div class="col-lg-6 col-md-6">
              <base-input
                :rules="{required: true}"
                name="Title"
                label="Title*"
                v-model="request.bariResource.title"
                placeholder="">
              </base-input>
            </div>
            <div class="col-lg-6 col-md-6">
              <label class="form-control-label">Select Category</label>
              <el-select
                class="w-100"
                v-model="request.bariResource.bari_resource_category_id"
                placeholder="Select Category"
                @change="getBariResourceSubCategoriesByCategory"
              >
                <el-option
                  class="select-danger"
                  :value="''"
                  :label="'Select Category'"
                  :key="''">
                </el-option>
                <el-option
                  v-for="category in response.categories"
                  class="select-danger"
                  :value="category.id"
                  :label="category.name"
                  :key="category.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-6">
              <label class="form-control-label">Select Sub Category</label>
              <el-select
                class="w-100"
                v-model="request.bariResource.bari_resource_sub_category_id"
                placeholder="Select Sub Category"
              >
                <el-option
                  class="select-danger"
                  :value="''"
                  :label="'Select Sub Category'"
                  :key="''">
                </el-option>
                <el-option
                  v-for="subCategory in response.subCategories"
                  class="select-danger"
                  :value="subCategory.id"
                  :label="subCategory.name"
                  :key="subCategory.id">
                </el-option>
              </el-select>
            </div>
            <div class="col-lg-6 col-md-6">
              <label class="form-control-label">Status*</label>
              <base-input name="status" :rules="{required: true}">
                <el-select
                  prop="status"
                  class="w-100 select-primary pagination-select"
                  v-model="request.bariResource.status"
                  :rules="{required: true}"
                  name="status"
                  placeholder="Status"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in dropdown.status"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-6">
              <base-input
                type="url"
                :rules="{required: true}"
                v-model="request.bariResource.link"
                name="link"
                label="Link*"
                placeholder="">
              </base-input>
            </div>
            <div class="col-lg-6 col-md-6">
              <base-input
                v-model="request.bariResource.engagement_time"
                name="Engagement Time"
                label="Engagement Time"
                placeholder="E.g 3 Minutes">
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center">
              <file-input name="image"
                          class="w-100"
                          :rules="{ extensions: ['jpg', 'jpeg', 'png']}"
                          label="Upload Preview Image"
                          ref="previewImage" @change="(files) => request.bariResource.preview_image = files[0]"
                          :uploaded-image="request.bariResource.old_preview_image"
                          @closeImage="() => request.bariResource.old_preview_image = ''"
              />
            </div>
          </div>

        </card>
      </form>
      <base-button
        @click="onSubmit()"
        class="btn-primary-action mb-4 br-4 pr-5 pl-5"
        native-type="submit"
        type="submit">
        {{ submitButtonText }}
      </base-button>
    </validation-observer>
  </div>
</template>

<script>
import {extend} from "vee-validate"
import {required} from "vee-validate/dist/rules"

extend("required", {
    ...required, message: (field) => {
      let name = field.substr(0, 1).toUpperCase() + field.substr(1);
      return `This ${name} is required.`
    }
  }
)

export default {
  name: "BariResourceForm",
  props: {
    submitButtonText: {
      type: String,
      default: 'Add',
      description: 'Submit Button Text'
    },
    bariResourceDataToEdit: {
      type: Object,
      default: () => {
        return {
          user_id: 48,
          bari_resource_category_id: '',
          bari_resource_sub_category_id: '',
          title: '',
          link: '',
          engagement_time: '',
          old_preview_image: '',
          preview_image: '',
          status: 1,
        };
      },
      description: 'Bari Resource type object'
    }
  },
  data() {
    return {
      loaders: {
        image: false,
        form: false,
      },
      response: {
        categories: [],
        subCategories: [],
      },
      request: {
        bariResource: {...this.$store.getters['BariResourceModule/getBariResource']}
      },
      dropdown: {
        status: [
          {
            value: 1, label: 'Active'
          },
          {
            value: 0, label: 'Inactive',
          },
        ],
      }
    }
  },
  watch: {
    bariResourceDataToEdit(newVal) {
      this.request.bariResource.id = newVal.id ?? ''
      this.request.bariResource.user_id = newVal.user_id ?? 48
      this.request.bariResource.title = newVal.title ?? ''
      this.request.bariResource.link = newVal.link ?? ''
      this.request.bariResource.engagement_time = newVal.engagement_time ?? ''
      this.request.bariResource.old_preview_image = newVal.preview_image ?? null
      this.request.bariResource.status = newVal.status ?? 1
      this.request.bariResource.bari_resource_category_id = newVal.bari_resource_category_id ?? ''
      this.request.bariResource.bari_resource_sub_category_id = newVal.bari_resource_sub_category_id ?? ''
      if (newVal.hasOwnProperty('bari_resource_category_id') && newVal.bari_resource_category_id) {
        this.getBariResourceSubCategoriesByCategory()
      }
    }
  },
  mounted() {
    this.getBariResourceCategories()
  },
  methods: {
    /**
     * Get all Bari resources Categories
     */
    getBariResourceCategories() {
      let vm = this
      vm.loaders.form = true
      vm.$store.dispatch('BariResourceModule/_getActiveBariResourceCategories')
        .then(response => {
          vm.response.categories = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Bari Resource Categories',
            message: message
          });
        })
        .finally(() => {
          vm.loaders.form = false
        })
    },

    /**
     * Get all Bari resources sub categories by category
     */
    getBariResourceSubCategoriesByCategory() {
      let vm = this
      vm.loaders.form = true
      this.request.bariResource.bari_resource_sub_category_id = ''
      const payload = {
        category_id: this.request.bariResource.bari_resource_category_id
      }
      vm.$store.dispatch('BariResourceModule/_getBariResourceSubCategoriesByCategory', payload)
        .then(response => {
          vm.response.subCategories = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Bari Resource Sub Categories',
            message: message
          });
        })
        .finally(() => {
          vm.loaders.form = false
        })
    },

    /**
     * Submit Form Action
     * @returns {Promise<void>}
     */
    async onSubmit() {
      let vm = this
      const isBariResourceFormValid = await this.$refs['formValidator'].validate();
      if (isBariResourceFormValid) {
        if (this.$route.name === 'StoreBariResource') {
          vm.$emit('storeBariResource', vm.request.bariResource);
        } else if (this.$route.name === 'EditBariResource') {
          vm.$emit('updateBariResource', vm.request.bariResource);
        }
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please Enter Required Fields.'
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
